<template>
  <div>
    <div class="d-flex" v-if="isEditMode">
      <v-autocomplete
        solo
        dense
        ref="input"
        return-object
        :value="newValue || item.value.id"
        :items="localSelectItems"
        item-value="id"
        item-text="value"
        @change="handleValueChange($event)"
        @keydown.esc="handleCancelClick()"
      >
        <template v-slot:item="{ item }">
          <v-list-item v-if="!item.id" @click="$emit('add-new-clicked')">
            <v-list-item-content>
                <v-list-item-title>
                    <v-icon>mdi-plus</v-icon>
                    {{ item.name }}
                </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <span v-else>{{ item.value }}</span>
        </template>
      </v-autocomplete>
      <v-icon
        color="red"
        class="ml-3 set-cursor-pointer"
        @click.native="handleCancelClick()"
      >
        mdi-close
      </v-icon>
    </div>
    <div
      v-else
      class="set-cursor-pointer"
      @click="handleItemClick()"
    >
      {{ item.value.value }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    selectItems: {
      type: Array,
      default: () => []
    },

    newValue: {
      type: Number,
      default: undefined
    }
  },

  data() {
    return {
      isEditMode: this.newValue !== undefined,
      localSelectItems: []
    }
  },

  watch: {
    selectItems() {
      this.prepareItems();
    },

    newValue() {
      this.isEditMode = this.newValue !== undefined;
    }
  },

  mounted() {
    this.prepareItems();
  },

  methods: {
    prepareItems() {
      this.localSelectItems = [...this.selectItems];

      if (this.hasPermissions('mapping-values-create')) {
        this.localSelectItems.push({
          divider: true
        });

        this.localSelectItems.push({
          name: this.$t('MAPPINGS.ADD_MAPPING_VALUE'),
        });
      }
    },

    handleItemClick() {
      this.isEditMode = true;

      this.$nextTick(() => {
        this.$refs.input.focus();
        this.$refs.input.isMenuActive = true;
        this.$nextTick(() => {
          this.$refs.input.$refs.input.select();
        });
      });
    },

    handleValueChange(item) {
      if (!item) {
        return;
      }

      this.$emit('item-updated', {
        id: this.item.id,
        valueId: item.id
      });
    },

    handleCancelClick() {
      this.isEditMode = false;

      this.$emit('item-update-cancelled', this.item.id);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.v-text-field__details) {
  display: none;
}
::v-deep(.v-input__slot) {
  margin-bottom: 0 !important;
}
</style>