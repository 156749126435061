<template>
  <div>
    <KTPortlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <v-card :disabled="isProcessing" :loading="isProcessing">
          <template v-slot:progress>
            <v-progress-linear
              absolute
              color="success"
              height="4"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1">
              <v-toolbar-title>
                {{ getTitle() }}
              </v-toolbar-title>
            </v-tab>
            <v-spacer></v-spacer>
            <div class="buttons-tab">
              <v-btn
                class="mr-4 primary"
                :disabled="!lastFetchParams"
                @click="handleDownloadDetailsClick()"
              >
                {{ $t('GENERAL.LABELS.DOWNLOAD_BUTTON') }}
              </v-btn>
            </div>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <v-card flat>
                <v-card-text>
                  <v-form
                  @submit.prevent
                  ref="requestData"
                  v-model="validRequestData">
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field v-model="requestData.name"  label="Name" :rules="[validations.required()]"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="requestData.type_id"
                          label="Type"
                          :items="types"
                          item-text="name"
                          item-value="id"
                          disabled
                          :rules="[validations.required()]"
                          @change="getTags();getGroups();"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="requestData.tag_id"
                           label="Tag"
                          :items="tags"
                          item-text="name"
                          item-value="id"
                          :disabled="!!$route.params.id"
                          :rules="[validations.required()]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="requestData.group_id"
                          label="Group"
                          :items="groups"
                          item-text="name"
                          item-value="id"
                           placeholder="Select"
                           :disabled="!!$route.params.id"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row v-if="$route.params.id && headersPrepared">
                      <v-col cols="12" offset="1" sm="10">
                        <Table
                          ref="mapping-details-table"
                          :key="tableKey"
                          :name="'mapping-details-table-' + $route.params.id"
                          :headers="headers"
                          :filters="filters"
                          :server-data-options="{
                            method: tableItemsMethod,
                            params: { mappingId: $route.params.id }
                          }"
                          :loading="isProcessing"
                          dense
                          fixed-header
                          multi-sort
                          filterable
                          @fetched-server-data="lastFetchParams = $event"
                          @overwritten-table-items="itemsForUpdate = {}"
                        >
                          <template v-for="(header, index) in headers" v-slot:[`item.${header.value}`]="{ item }">
                            <EditableMappingTableItem
                              :key="item.item.id"
                              v-if="header.value === 'value.value'"
                              :item="item.item"
                              :new-value="itemsForUpdate[item.item.id]?.valueId"
                              :selectItems="
                                requestData.group_id && item.item.value.group_value ?
                                  mappingGroupedValues[item.item.value.group_value] :
                                  mappingGroupedValues['']
                              "
                              @add-new-clicked="handleAddNewMappingValueClick(item.item.value.group_value_id, item.item)"
                              @item-updated="handleMappingDetailUpdated"
                              @item-update-cancelled="handleMappingDetailUpdateCancelled"
                            />
                            <span
                              :key="`offers-count-${index}`"
                              v-else-if="header.value === 'offers_count'"
                              class="set-cursor-pointer"
                              @click="handleMappingDetailNumberClick(item.item)"
                            >
                              {{ item.value }}
                            </span>
                            <span
                              :key="'options-row-index-' + index"
                              v-else-if="header.value === 'options' && item.item.offers_count == 0"
                            >
                              <v-icon
                                class="mx-2"
                                dark
                                color="red"
                                @click="handleDeleteMappingClick(item.item.id)"
                              >
                                mdi-trash-can
                              </v-icon>
                            </span>
                            <template v-else>
                              {{ item.value }}
                            </template>
                          </template>
                        </Table>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="warning" v-on:click="back()">Back</v-btn>
                  <v-btn color="primary" v-on:click="store()">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <AddEditMappingValueDialog
            v-model="saveDialog"
            :item="newMappingValue"
            :group-name="newMappingValue.groupValue?.value"
            :parent-items="mappingGroupedValues[newMappingValue.groupValue?.value || '']"
            @close="clearNewMappingValueData"
            @mapping-value-created="handleMappingValueCreated"
          />
          <Confirm
            v-model="deleteConfirm"
            :content="$t('GENERAL.DELETE_CONFIRM')"
            @confirm="deleteMapping"
            @decline="deleteConfirm = false"
          />
          <Confirm
            v-model="downloadConfirm"
            :content="$t('MAPPINGS.DOWNLOAD_BIG_FILE_CONFIRM')"
            @confirm="downloadDetails"
            @decline="downloadConfirm = false"
          />
        </v-card>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TableService from "@/common/table.service";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import {
  GET_MAPPINGS,
  GET_MAPPING,
  GET_DETAILS,
  DELETE_MAPPING_DETAIL,
  CREATE_MAPPING,
  UPDATE_MAPPING,
  DOWNLOAD_MAPPING_DETAILS
} from "@/store/mappings.module";
import { GET_MAPPING_VALUES, GET_MAPPING_VALUES_GROUPED } from "@/store/mappingValues.module";
import { SHOW_SNACK } from "@/store/snack.module";
import { GET_ONLY_SELECT_CATEGORIES } from "@/store/tagCategory.module";
import { GET_TAG_CATEGORY_USES } from "@/store/tagCategoryUse.module";
import Table from "@/views/partials/v-table/Table.vue";
import Confirm from "@/views/partials/dialogs/Confirm.vue";
import EditableMappingTableItem from "@/views/pages/mappings/partials/EditableMappingTableItem.vue";
import validations from "@/views/partials/form/Validations.vue";
import AddEditMappingValueDialog from "@/views/pages/mappings/partials/AddEditMappingValueDialog.vue";

export default {
  components: {
    KTPortlet,
    Table,
    EditableMappingTableItem,
    Confirm,
    AddEditMappingValueDialog
  },

  name: "StoreMappinges",

  data() {
    return {
      id:null,
      validations: validations,
      tab: "tab-1",
      tableKey: 1,
      saveDialog: false,
      isProcessing: false,
      headersPrepared: false,
      requestData: {
        name: '',
        type_id: 1,
        tag_id: null,
        group_id: null
      },
      newMappingValue: {
        id: null,
        mapping_id: this.$route.params.id,
        value: '',
        group_value_id: null,
        parent_id: null,
        groupValue: null,
        currentEditItem: null
      },
      validRequestData:false,
      aliases: {},
      mappingValues: [],
      mappingGroupValues: [],
      mappingGroupedValues: {},
      tags: [],
      types: [],
      groups:[],
      itemsForUpdate: {},
      lastFetchParams: null,
      tableItemsMethod: GET_DETAILS,
      deleteConfirm: false,
      downloadConfirm: false,
      mappingIdForDelete: null,
      headers: [
        {
          text: 'Alias',
          value: 'alias',
          align: 'center',
          width: '23%'
        },
        {
          text: 'Group',
          value: 'value.group_value',
          align: 'center',
          width: '23%'
        },
        {
          text: 'Value',
          value: 'value.value',
          align: 'center',
          width: '23%',
          editOptions: {
            model: 'value',
            type: 'autocomplete',
            'return-object': true,
            'item-value': 'id',
            'item-text': 'text',
            items: []
          }
        },
        {
          text: 'Number of type',
          value: 'offers_count',
          align: 'center',
          width: '23%'
        },
        {
          text: this.$t('GENERAL.TABLE.OPTIONS_COLUMN'),
          value: 'options',
          align: 'center',
          sortable: false,
          width: '8%',
        },
      ],
      filters: [
        {
          key: 'value',
          label: 'Value',
          type: 'select',
          'item-value': 'value',
          'item-text': 'value',
          items: []
        }
      ]
    };
  },

  mounted() {
    this.id = this.$route.params.id;

    this.getTypes();
    this.getTags();
    this.getGroups();

    if (this.id) {
      this.isProcessing = true;

      Promise.all([
        this.selectMpping()
      ]).then(() => {
        this.getMappingValuesAndGroups().then(() => {
          this.isProcessing = false;
        });
      });
    }
  },

  methods: {
    selectMpping() {
      return this.$store
        .dispatch(GET_MAPPING, this.id)
        .then((response) => {
          this.requestData = response;

          if (this.requestData.group_id) {
            this.filters.push({
              key: 'group',
              label: 'Group',
              type: 'select',
              'item-value': 'id',
              'item-text': 'value',
              items: this.mappingGroupValues
            });
          } else {
            this.headers.splice(1, 1);
          }
        });
    },

    getTypes() {
      this.$store.dispatch(GET_TAG_CATEGORY_USES).then((response) => {
        this.types = response.data;
      });
    },

    getTags() {
      this.$store
        .dispatch(GET_ONLY_SELECT_CATEGORIES, { type: this.requestData.type_id })
        .then((response) => {
          this.tags = response;
        });
    },

    getGroups() {
      this.$store
        .dispatch(GET_MAPPINGS, this.requestData.type_id)
        .then((response) => {
          this.groups = response;
          this.groups.unshift({id:'',name:'Select'});
        });
    },

    getMappingValuesAndGroups() {
      let mappingValuesPromises = [this.getMappingValues(), this.getMappingGroupedValues()];

      if (this.requestData.group_id) {
        mappingValuesPromises.push(this.getMappingGroupValues());
      }

      return Promise.all(mappingValuesPromises);
    },

    getMappingValues() {
      let params = {
        id: this.id,
        'unique-values': true
      };

      return this.$store
        .dispatch(GET_MAPPING_VALUES, params)
        .then((response) => {
          this.mappingValues = response.map((item) => {
            item.text = item.group_value_id ? `${item.value} [${item.group_value}]` : item.value;
            return item;
          });

          this.headers[this.requestData.group_id ? 2 : 1].editOptions.items = this.mappingValues;
          this.filters[0].items = this.mappingValues;

          this.headersPrepared = true;
        });
    },

    getMappingGroupValues() {
      return this.$store
        .dispatch(GET_MAPPING_VALUES, { id: this.requestData.group_id })
        .then((response) => {
          this.mappingGroupValues = response;
          this.filters[1].items = this.mappingGroupValues;
        });
    },

    getMappingGroupedValues() {
      return this.$store.dispatch(GET_MAPPING_VALUES_GROUPED, this.id)
        .then((response) => {
          this.mappingGroupedValues = response;
        });
    },

    store() {
      this.$refs.requestData.validate();

      if (!this.validRequestData) {
        return;
      }

       this.isProcessing = true;

       let url = CREATE_MAPPING;
       let params = this.requestData;

       if(this.id){
           url = UPDATE_MAPPING;
           params = {
              id: this.id,
              params: {
                ...this.requestData,
                details: this.itemsForUpdate
              }
           };
       }

      this.$store
        .dispatch(url, params)
        .then((resource) => {
          this.$store.dispatch(SHOW_SNACK, {
            message: this.$t('GENERAL.SUCCESS_MESSAGE')
          });

          if (!this.id) {
            this.back();
          } else {
            this.tableKey++;
            this.itemsForUpdate = {};

            this.$store.dispatch(SHOW_SNACK, {
              type: 'warning',
              message: this.$t('MAPPINGS.LIVE_LIST_NOT_IMMEDIATELY_APPEARS')
            });
          }
        })
        .catch((response) => {
        })
        .finally(() => {
          this.isProcessing = false;
           this.$refs.requestData.resetValidation();
        });
    },

    handleAddNewMappingValueClick(groupId, item) {
      if (groupId) {
        this.newMappingValue.groupValue = this.mappingGroupValues.find((item) => item.id === groupId);
        this.newMappingValue.group_value_id = groupId;
      }

      this.newMappingValue.currentEditItem = item;

      this.saveDialog = true;
    },

    handleMappingDetailUpdated(item) {
      this.itemsForUpdate[item.id] = {
        id: item.id,
        valueId: item.valueId
      };
    },

    handleMappingDetailUpdateCancelled(id) {
      this.itemsForUpdate[id] = undefined;
    },

    handleMappingDetailNumberClick(item) {
      var tableName = 'VueTable_vendorOffersTable_preferences';

      let state = TableService.getState(tableName);

      let filters = [
        {
          component: "string",
          data: `vendor_offers.tags->${this.requestData.tag_id}`,
          label: this.requestData.tag_name,
          mode: "exactMatch",
          type: "string",
          value: item.alias
        },
        {
          component: "enum",
          data: `mapping_values.${this.requestData.tag_id}.value`,
          label: this.requestData.name,
          mode: null,
          type: "enum",
          value: [item.value.value]
        }
      ];

      if (item.value.group_value) {
        filters.push({
          component: "enum",
          data: `mapping_values.${this.requestData.group_tag_id}.value`,
          label: "M-MeatGroup",
          mode: null,
          type: "enum",
          value: [item.value.group_value]
        });
      }

      state.meta.search = "";
      state.filterScenarios = [{
          active:true,
          edit:true,
          filters: filters,
          name: `Mapping list "${item.alias}"`
      }];

      TableService.setState(state, tableName);

      let routeData = this.$router.resolve({
        name: 'list-offers'
      });

      window.open(routeData.href, "_blank");
    },

    handleDeleteMappingClick(id) {
      this.mappingIdForDelete = id;
			this.deleteConfirm = true;
    },

    deleteMapping() {
      this.$store
        .dispatch(DELETE_MAPPING_DETAIL, this.mappingIdForDelete)
        .then(() => {
          this.$refs['mapping-details-table'].deleteServerItem(this.mappingIdForDelete);
          delete this.itemsForUpdate[this.mappingIdForDelete];
          this.isProcessing = false;

          this.$store.dispatch(SHOW_SNACK, {
            type: 'warning',
            message: this.$t('MAPPINGS.LIVE_LIST_NOT_IMMEDIATELY_APPEARS')
          });
        })
        .catch((response) => {
          this.$store.dispatch(SHOW_SNACK, {
            type: 'error',
            message: this.$t('GENERAL.UNEXPECTED_ERROR')
          });
        });
    },

    handleMappingValueCreated(mappingValue) {
      this.mappingGroupedValues[this.newMappingValue.groupValue?.value || ''].push(mappingValue);

      this.$store.dispatch(SHOW_SNACK, {
        type: 'warning',
        message: this.$t('MAPPINGS.LIVE_LIST_NOT_IMMEDIATELY_APPEARS')
      });
    },

    clearNewMappingValueData() {
      this.newMappingValue.id = null;
      this.newMappingValue.value = '';
      this.newMappingValue.group_value_id = null;
      this.newMappingValue.parent_id = null;
      this.newMappingValue.groupValue = null;
      this.newMappingValue.currentEditItem = null;
    },

    handleDownloadDetailsClick() {
      if (this.lastFetchParams.totalItems > 1000) {
			  this.downloadConfirm = true;
      } else {
        this.downloadDetails();
      }
    },

    downloadDetails() {
      this.$store
        .dispatch(DOWNLOAD_MAPPING_DETAILS, this.lastFetchParams.params)
        .then((resource) => {
            location.href = resource.url;
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },

    back() {
      this.$router.push({ name: "mappings" });
    },
    getTitle(){
        return this.$route.params.id ? this.$t("MAPPINGS.EDIT") : this.$t("MAPPINGS.CREATE");
    }
  },
};
</script>

<style>
</style>